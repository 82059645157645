import React, { Fragment, useEffect, useState } from 'react';
import Axios from 'axios';
import './styles.css';

const URL = 'https://inconnect.oninnovaccer.com/api/healthEducation/api';

const healthEducationApi = Axios.create({
  baseURL: URL,
  headers: {
    usertype: 'patient',
  },
});

healthEducationApi.interceptors.response.use(
  (res) => res,
  (err) => {
    console.log(err);
  }
);

export const Articles = (props) => {
  const [articleData, setArticleData] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { documentId, sectionId, authToken } = props;
  // let searchTimeInterval = null;

  useEffect(() => {
    fetchArticle();
    // attachEventListeners();
  }, []);

  // useEffect(() => {
  //   addContinueReading();

  //   //TODO: Update refs after the dynamic html is available in the document.
  //   searchTimeInterval = setInterval(() => {
  //     updateHrefs();
  //   }, 400);
  //   setTimeout(() => {
  //     !!searchTimeInterval && clearInterval(searchTimeInterval);
  //   }, 5000);
  // }, [articleData]);

  const addContinueReading = () => {
    let contReadingWrapper = document.createElement('div');
    contReadingWrapper.className = 'Continue-reading-wrapper';
    let contReadingButton = document.createElement('button');
    let textnode = document.createTextNode('Continue Reading');
    contReadingButton.onclick = continueReading;
    contReadingButton.appendChild(textnode);
    contReadingWrapper.appendChild(contReadingButton);

    let contentElement = document.getElementsByClassName('HwContent')[0];
    if (
      contentElement &&
      contentElement.offsetHeight > window.innerHeight * 1.5
    ) {
      contentElement.style.height = '200vh';
      contentElement.style.overflowY = 'hidden';
      contentElement.parentNode.insertBefore(
        contReadingWrapper,
        contentElement.nextSibling
      );
    }
  };

  const continueReading = () => {
    let contReadingWrapper = document.getElementsByClassName(
      'Continue-reading-wrapper'
    )[0];
    contReadingWrapper.style.display = 'none';
    const element = document.getElementsByClassName('HwContent')[0];
    element.style.height = 'auto';
    element.style.overflowY = 'auto';
  };

  const updateHrefs = () => {
    const links = document.getElementsByTagName('a');
    console.log('hitting');
    if (!!links.length) {
      // debugger;
      console.group();
      [...links].map((link) => {
        if (!!link.href && link.className !== 'HwLinkDisclaimer') {
          link.href = `#/document/${link.dataset.documentHref}/${link.dataset.sectionHref}`;
        }
        console.log({ link });
      });
      console.groupEnd();
      // clearInterval(searchTimeInterval);
    }
  };

  const attachEventListeners = () => {
    window.addEventListener('click', (event) => {
      if (
        event.target.tagName.toLowerCase() === 'a' &&
        event.target.className !== 'HwLinkDisclaimer'
      ) {
        if (window.location.href.indexOf('article') === -1)
          event.target.href = '#';
        // window.location.hash = '';
        window.location.pathname = `document/${
          event.target.dataset.articleid
        }/${event.target.dataset.topicId || ''}`;
      }
      if (
        event.target.tagName.toLowerCase() === 'span' &&
        event.target.parentElement.tagName.toLowerCase() === 'a' &&
        event.target.parentElement.className !== 'HwLinkDisclaimer'
      ) {
        if (window.location.href.indexOf('article') === -1)
          event.target.href = '#';
        // event.target.parentElement.href = '/';
        // window.location.hash = '';
        window.location.pathname = `document/${
          event.target.parentElement.dataset.articleid
        }/${event.target.parentElement.dataset.topicId || ''}`;
      }
    });
  };

  const updateHead = (data, externalJavacript) => {
    const head = document.getElementsByTagName('head')[0];
    head.insertAdjacentHTML('beforeend', data);
    externalJavacript.forEach(({ target }) => {
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = target;
      document.body.appendChild(script);
    });
  };

  const fetchArticle = () => {
    setIsLoading(true);
    healthEducationApi.defaults.headers.Authorization = `Bearer ${authToken}`;
    const articleId = window.location.pathname.split('/')[2];

    healthEducationApi
      .get(`/content/articles/${articleId || documentId}/en-us/`)
      .then((response) => {
        if (response && response.data) {
          setArticleData(response.data);
          updateHead(
            response.data.pageHtml.headHtml,
            response.data.resources.javascript
          );
          setTimeout(() => {
            addContinueReading();
            updateHrefs();
          }, 2000);
        }
      })
      .catch(console.log)
      .finally(() => setIsLoading(false));
  };

  const getHtml = () => ({ __html: articleData.pageHtml.bodyHtml });

  return isLoading ? (
    <div className="Loader-wrapper">
      <div className="Loader">Loading...</div>
    </div>
  ) : (
    <div className="Articles-wrapper">
      {!!articleData ? (
        <Fragment>
          <div className="Header-section">
            <h2>{articleData.title}</h2>
            <p>{articleData.abstract}</p>
            <div>
              <p>{articleData.credits.author.name}</p>
              <p>{articleData.certifiedDate}</p>
            </div>
          </div>
          <div dangerouslySetInnerHTML={getHtml()} />
        </Fragment>
      ) : (
        <div className="Empty-data-wrapper">
          <h2>Something went wrong.</h2>
          <p>Please refresh the page.</p>
        </div>
      )}
    </div>
  );
};
